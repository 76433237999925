.player_page {
  color: #fff;
  padding: 16px;
  text-align: center;
}

.player_photo_container {
  padding: 8px;
}

.player_photo {
  width: 100%;
  border-radius: 22px;
  margin-bottom: 8px;
}

.player_info {
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
  align-items: center;
}

.player_info_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 1em;
  padding-bottom: 16px;
}

.player_number {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: white;
}

.player_number_text {
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.player_info_wrapper div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.player_info_block {
  height: 70px;
}

.country_flag {
  height: 35px;
  border-radius: 6px;
}

.team_logo {
  width: 65px;
  height: 65px;
  transition: all 500ms ease;
}

.team_logo:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.player_stats {
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
}

.stats_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat_icon {
  width: 40px;
  margin-bottom: 4px;
}

.achievements_section {
  background-color: #1e1e1e;
  border-radius: 16px;
  padding: 16px;
}

.achievements_list {
  list-style: none;
  padding: 0;
}

.achievement_item {
  display: flex;
  align-items: center;
  margin: 16px 8px;
  text-align: start;
}

.achievement_color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
}

.player_name {
  font-size: 24px;
  margin-bottom: 8px;
}

.stats_item p {
  font-size: 22px;
  font-weight: bold;
}

.achievement_icon {
  width: 30px;
  margin-right: 8px;
}

.achievement_text {
  font-size: 14px;
}

.container {
  margin: auto auto;
  padding: 0 20px;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.backButton {
  text-decoration: none;
  border-radius: 5px;

  background-color: black;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  border: 2px solid #fff;
  color: #fff;

  background: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 6px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.backButton:hover {
  background-color: #1e1e1e;
}

.subTitle {
  font-size: 20px;
  margin: 8px 0;
}

.text {
  font-size: 14px;
  text-align: left;
}

.description {
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  width: 100%;
}

.title {
  font-size: 28px;
  font-weight: 500;
  margin: 8px 0;
}

.header {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 30px;
  justify-content: space-between;
}

.footer {
  color: #fff;
  text-align: center;
  padding: 20px 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.link {
  color: #ffed00;
  text-decoration: none;
  color: #fff;
}

.link:hover {
  text-decoration: underline;
}

.contactsContainer {
  /* max-width: 600px; */
  /* margin: 50px auto; */
  padding: 20px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #f9f9f9; */
  /* border-radius: 10px; */
  /* text-align: center; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.email {
  display: inline-block;
  font-size: 18px;
  color: #ff9800;
  text-decoration: none;
  font-weight: bold;
  margin: 8px 0;
}

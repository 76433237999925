.header {
  top: 0;
  width: 100%;

  z-index: 1000;
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 0;
  transition: padding 0.3s ease, margin-bottom 0.3s ease;
  margin-bottom: 8px;
}

#logo {
  height: 145px;
  transition: height 0.3s ease;
}

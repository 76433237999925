.navigation {
  display: flex;
  background-color: black;
  padding: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  border: 2px solid #fff;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 8px;
}

.navigation button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  flex: 1;
  white-space: nowrap;
}

.navigation button.active {
  background-image: linear-gradient(90deg, #ffde59, #ff914d);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navigation button:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}

.card_container {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card {
  background-image: linear-gradient(90deg, #ffde59, #ff914d);
  border-radius: 30px;
  padding: 2px;
  margin: 12px 0;
}

.card_header {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
}

.card_content_container {
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.match_date {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  font-size: 12px;
}

.centered_skeleton {
  margin: 0 auto;
}

.centered_skeleton span {
  margin-bottom: 0;
}

.skeleton_container {
  padding: 12px 20px 12px;
  margin: 2px 0;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.match_block:active {
  cursor: pointer;
  background-color: #1e1e1e !important;
}

.match_container {
  border-radius: 26px;
}
.match_container:last-of-type .match_block:active {
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
}

.simple_card_wrapper {
  background-image: linear-gradient(90deg, #ffde59, #ff914d);
  border-radius: 30px;
  padding: 2px;
  margin-bottom: 16px;
}

.simple_card_container {
  background-color: #000;
  border-radius: 30px;
}

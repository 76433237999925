.card {
  background-color: #202020;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
}

.card_header {
  font-size: 1.5em;
  color: #f7b500;
  text-align: center;
  margin-bottom: 20px;
}

.card_content_container {
  padding: 10px;
}

.statistics_list {
  list-style-type: none;
  padding: 0 12px 12px 12px;
}

.stat_item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  justify-content: space-between;
}

.stat_item div {
  display: flex;
}

.stat_icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.stat_text {
  font-size: 1.2em;
  color: #ffffff;
  line-height: 1.3em;
}

.stat_value {
  font-size: 1.2em;
  color: #ffffff;
  line-height: 1.3em;
}

/* Стили для кругов */
.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.win {
  background-color: #28a745; /* зеленый цвет для побед */
}

.draw {
  background-color: #ffc107; /* желтый цвет для ничьей */
}

.loss {
  background-color: #dc3545; /* красный цвет для поражений */
}

.match_count_block {
  display: flex;
  padding: 12px 12px 12px 12px;
  justify-content: center;
  border-bottom: 1px solid #1e1e1e;
}

.match_container {
  display: flex;
  padding: 10px;
  justify-content: center;
}

.team_card {
  display: flex;
  align-items: center;
  width: 40%;
  font-weight: bold;
  justify-content: space-between;
}

.gradient_container {
  padding: 4px 0;
  width: 20%;
  background-image: linear-gradient(90deg, #ffde59, #ff914d);
  align-content: center;
  justify-content: center;
  align-items: center;

  border-radius: 30px;
}

.score_container {
  padding: 2px;
  margin: 0 8px;
  display: flex;

  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: black;
  width: 16%;
}

.gradient-border {
  background: linear-gradient(90deg, #ffde59, #ff914d);
  position: relative;
}

.gradient-border span {
  display: block;
  background-color: #000;
  padding: 6px 0;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.match-result-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 20px 12px;
  margin: 4px 0;
  color: #fff;
}

.match-result-container:last-of-type {
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
}

.match-result-container:first {
  border-radius: 0 !important;
}

.match-result-container:hover {
  cursor: pointer;
}

.match-result-container:active {
  cursor: pointer;
  background-color: #1e1e1e;
}

.match-result-fake-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 20px 12px;
  margin: 4px 0;
  color: #fff;
}

.team-card {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
}

.second-team-card {
  width: 42%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden; /* Скрыть переполняющий контент */
}

.team-logo {
  width: 30px;
  height: 30px;
  margin: 0 4px;
  /* background-color: #ccc; */ /*  Замените на ваш фон или используйте изображение */
  flex-shrink: 0; /* Логотип не будет уменьшаться */
}

.team-name {
  font-size: 12px;
  /* font-weight: bold; */
  /* text-align: left; */
  white-space: nowrap; /* Запрет на перенос строк */
  overflow: hidden; /* Скрыть текст, который не помещается */
  text-overflow: ellipsis; /* Добавить многоточие в конце обрезанного текста */
}

.match-score {
  width: 16%;
  padding: 2px;
  background-color: #ff9800; /* Цвет фона */
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  flex-shrink: 0; /* Счет не будет уменьшаться */
}

.footer {
  color: #fff;
  text-align: center;
  padding: 20px 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.link {
  color: #ffed00;
  text-decoration: none;
  color: #fff;
}

.link:hover {
  text-decoration: underline;
}

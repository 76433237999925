.team-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 100vh; */
  width: 100%;
  color: white;
}

.team-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.team-page-name {
  color: white;
  font-size: 34px;
  font-weight: bold;
}

.team-page-logo {
  width: 180px;
  height: 180px;
  object-fit: contain;
}

.team-tabs {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.team-tabs button {
  margin: 0 8px;
  padding: 8px 16px;
  background: #ff914d;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.team-tabs button.active {
  background: #ffde59;
}

.team-content {
  width: 90%;
  /* margin-top: 16px; */
}

.team-players,
.team-statistics,
.team-schedule {
  margin-top: 16px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin: 8px 0;
}

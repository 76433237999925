.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #000;
  color: #fff;
}

h1 {
  font-size: 7rem;
  margin-bottom: 20px;
}

button {
  background-color: #f9a826;
  text-decoration: none;
  border-radius: 5px;

  background-color: black;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  border: 2px solid #fff;
  color: #fff;

  background: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 6px 12px;
  border-radius: 30px;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #e08e24;
}

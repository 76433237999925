/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
}

.MuiBottomNavigation-root {
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.MuiBottomNavigationAction-root {
  min-width: 0;
  max-width: 100px;
}

@media (min-width: 600px) {
  .MuiBottomNavigationAction-root {
    max-width: 200px;
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #1e1e1e;
  color: white;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

html,
body,
button {
  font-family: "Play", sans-serif;
}

button,
a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

button,
a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@media (hover: none) {
  button:hover,
  a:hover {
    background-color: inherit;
  }
}

@media (min-width: 768px) {
  .desktop-warning {
    display: block;
    text-align: center;
    font-size: 26px;
    color: lightgrey;
    padding: 16px 0;
    text-transform: uppercase;
    background-color: #000;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .desktop-warning {
    display: none;
  }
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1; /* Фиксируем фон за контентом */
  background-color: black;
}

#content {
  position: relative;
  overflow-y: auto; /* Прокрутка в контейнере */
  height: 100%;
  z-index: 1;
}

body {
  margin: 0;
  overflow: auto; /* Обеспечиваем естественную прокрутку */
  -webkit-overflow-scrolling: touch; /* Добавляем плавную прокрутку для мобильных устройств */
}

html,
body {
  height: 100%;
}

html,
body,
button,
a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.play-regular {
  font-family: "Play", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.play-bold {
  font-family: "Play", sans-serif;
  font-weight: 700;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  background-color: black;
}

@media only screen and (max-width: 480px) {
  #background {
    background-image: url("./assets/images/background-mobile.jpg");
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  #background {
    background-image: url("./assets/images/background-tablet.jpg");
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #background {
    background-image: url("./assets/images/background-small-laptop.jpg");
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1920px) {
  #background {
    background-image: url("./assets/images/background-desktop.jpg");
  }
}

@media only screen and (min-width: 1921px) {
  #background {
    background-image: url("./assets/images/background-4k.jpg");
  }
}

@media only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi) {
  #background {
    background-image: url("./assets/images/background-mobile@x2.jpg");
  }
}

#content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll; /* Разрешаем прокрутку контента, но фон остается на месте */
  z-index: 1;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1; /* Фиксируем фон за контентом */
  background-color: black;
}

#content {
  position: relative;
  overflow-y: auto; /* Прокрутка в контейнере */
  height: 100%;
  z-index: 1;
}

body {
  margin: 0;
  overflow: auto; /* Обеспечиваем естественную прокрутку */
  -webkit-overflow-scrolling: touch; /* Добавляем плавную прокрутку для мобильных устройств */
}

html,
body {
  height: 100%;
}

html,
body,
button,
a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.socialMediaMenu {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 16px;
}

.socialMediaMenu .icon {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.socialMediaMenu .icon {
  width: 40px;
  height: 40px;
  transition: transform 0.2s ease, background-color 0.2s ease;
  touch-action: manipulation;
}

.socialMediaMenu .icon:active {
  transform: scale(0.95);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.innerPageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.pageContent {
  flex-grow: 1;
}

.header {
  display: flex;
  margin: 16px;
  border-radius: 30px;
  justify-content: space-between;
}

.backButton {
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 5px;

  background-color: black;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  border: 2px solid #fff;
  color: #fff;

  background: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 6px 12px;
  border-radius: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.backButton:hover {
  background-color: #1e1e1e;
}

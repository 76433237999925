.team_roster {
  /* padding: 16px 0; */
}

.section_header {
  font-size: 18px;
  font-weight: bold;
  padding: 12px;
  border-top: 1px solid #1e1e1e;
}

.section_header:first-of-type {
  font-size: 18px;
  font-weight: bold;
  padding: 12px;
  border-top: 0;
}

.player_row {
  display: flex;
  align-items: center;
  border-top: 1px solid #1e1e1e;
  padding: 8px 24px;
}

.player_row:hover {
  cursor: pointer;
}

.player_row:active {
  background-color: #1e1e1e;
  cursor: pointer;
}

.player_row:last-of-type {
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
}

.player_number {
  width: 30px;
  font-weight: bold;
  margin-right: 10px;
  color: white;
}

.player_photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.player_name {
  font-size: 16px;
}

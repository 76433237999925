.tableContainer {
  overflow-x: auto;
  margin-top: 12px;
  margin-bottom: 12px;

  padding: 2px;
  border-style: hidden;

  background-image: linear-gradient(90deg, #ffde59, #ff914d);
  align-content: center;
  justify-content: center;
  align-items: center;

  border-radius: 30px;
  width: 90%;
}

.table {
  width: 100%;
  color: white;
  border-collapse: collapse;
  border-radius: 30px;
  overflow: hidden;
  border-collapse: collapse;
  table-layout: auto;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  height: 50px;

  border-bottom: 1px solid #1e1e1e !important;
}

.table td,
.table th {
  background-color: black;
}

.table th {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  vertical-align: middle;
}

.table tr {
  transition: background-color 0.3s ease;
}

.table tr.active {
  background-color: #ff9800;
}

.table td {
  vertical-align: middle;
  font-size: 14px;
}

.teamLogo {
  width: 40px;
  margin-right: 8px;
  vertical-align: middle;
  flex-shrink: 0;
  display: inline-block;
  animation: fadeIn 0.25s ease-in-out;
}

.table-footer-note {
  text-align: center;
  font-size: 10px;
}

.columnCentered {
  text-align: center !important;
  vertical-align: middle;
}

.priorityCell {
  padding: 0 0 0 8px !important;
}

.orderCell {
  padding-left: 4px !important;
}

.teamNameCell {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}

.logoNameCell {
  vertical-align: middle;
}

.teamLogoName {
  display: inline-block;
  vertical-align: middle;
}

.priority {
  height: 36px;
  width: 8px;
}

table tr:nth-child(1) td div.priority,
table tr:nth-child(2) td div.priority,
table tr:nth-child(3) td div.priority,
table tr:nth-child(4) td div.priority {
  background-color: #fdc200;
}

table tr:nth-child(5) td div.priority,
table tr:nth-child(6) td div.priority {
  background-color: #fd6f15;
}

.table td:last-child,
.table th {
  height: 24px;
}

@media only screen and (min-width: 410px) {
  .teamNameCell {
    max-width: 145px;
  }
}

@media only screen and (max-width: 360px) {
  .teamNameCell {
    max-width: 75px;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.tablePad {
  background-color: black;
  border-radius: 30px;
}

.playerPhoto {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  vertical-align: middle;
  flex-shrink: 0;
  display: inline-block;
  animation: fadeIn 0.25s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.clickableRow:hover {
  cursor: pointer;
}

.table tr:active td {
  background: #1e1e1e;
  cursor: pointer;
}

.unClickableRow:active td {
  background: black !important;
  cursor: initial !important;
}

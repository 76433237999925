.match-page {
  color: #fff;
  margin: 16px;
}

.match-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.match-team-logo img {
  width: 4.3rem;
  height: 4.3rem;
  /* padding: 0 0.5rem; */
  transition: all 500ms ease;
}

.match-team-logo:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.score {
  font-size: 3rem;
  font-weight: bold;
}

.unavailable-message {
  font-size: 1.5rem;
  padding: 40px 20px;
}

.timeline-container {
  padding-bottom: 8px;
}

.timeline-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.5rem;
  margin: 0;
}

.event-details {
  font-size: 0.9rem;
}

.timeline-item.left .event-details {
  text-align: left;
}

.timeline-item.right .event-details {
  text-align: right;
}

.event-icon {
  width: 20px;
  height: 20px;
}

.penalty-info {
  padding: 16px;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.main-score-penalty-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
}

.watch-match-button {
  text-align: center;
}

.watch-match-button button {
  border: none;
  padding: 10px 20px;
  width: 100%;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.watch-match-button button img {
  margin: 0 8px;
  height: 24px;
  width: 24px;
}

.watch-match-button button:active {
  background-color: #1e1e1e;
}

.MuiTimelineDot-root {
  padding: 0;
  margin: 10px 0 !important;
}

.score {
  font-size: 3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.team-score {
  display: inline-block;
  min-width: 2ch;
}

.score-a {
  text-align: end;
}

.score-b {
  text-align: start;
}

.dash {
  display: inline-block;
  width: 1ch; /* Тире остается всегда в центре */
  text-align: center;
}

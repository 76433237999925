.team_matches {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.team_matches_content {
  width: 100%;
}
